@import url('https://fonts.googleapis.com/css?family=Open+Sans:300, 700&display=swap');

:root {
  --primary: #4a85a7;
  --accent: #eeeeee;
  --secondary: #d8e6e4;
  --text: #000000;
  --background: #FFFFFF;
  --gutter: 8px;
  --gutter-2: 16px;
  --gutter-3: 24px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent: #333333;
    --text: #ffffff;
    --background: #222222;
  }
}

body {
  background-color: var(--background);
  margin: 0;
  color: var(--text);
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

a {
  color: var(--primary);
}

.link {
  padding: 0;
  background: none;
  border: 0;
  color: var(--primary);
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}
